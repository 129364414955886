/* You can add global styles to this file, and also import other style files */

@import url(node_modules/primeng/resources/themes/lara-light-blue/theme.css);
@import url(node_modules/primeng/resources/primeng.min.css);
@import url(node_modules/primeicons/primeicons.css);

/* BEGIN - CSS Reset */

/*
  Allow percentage-based heights in the application.
*/
html,
body {
	height: 100%;
	margin: 0px;
}
/*
  Improve text rendering on MacOS.
*/
body {
	-webkit-font-smoothing: antialiased;
}
/*
  Improve media defaults.
*/
img,
picture,
video,
canvas,
svg {
	display: block;
	width: 100%;
}
/*
  Remove built-in form typography styles.
*/
input,
button,
textarea,
select {
	font: inherit;
}
/*
  Prevent text from overflowing its container.
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}

.side-by-side {
	display: inline-block;
}
