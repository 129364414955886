// Theme Specific Variables
$blue-200: #b3dce1;
$blue-400: #66b8c4;
$blue-800: #004852;
$blue-1000: #001d21;
$warmGray-100: #f5f1e6;
$warmGray-200: #ebe7dd;
$warmGray-300: #d6d4c9;
$warmGray-400: #c2bfb6;
$warmGray-500: #a6a49c;
$yellow-500: #ffc00d;
$yellow-700: #d9a100;
$warm-white: #fcfbf7;

$primaryLightColor: $blue-200 !default;
$primaryColor: $blue-400 !default;
$primaryDarkColor: $blue-800 !default;
$primaryDarkerColor: $blue-1000 !default;
$primaryTextColor: black !default;

$colors: (
    'blue': #2196f3,
    'green': #4caf50,
    'yellow': #fbc02d,
    'cyan': #00bcd4,
    'pink': #e91e63,
    'indigo': #3f51b5,
    'teal': #009688,
    'orange': #f57c00,
    'bluegray': #607d8b,
    'purple': #9c27b0,
    'red': #ff4032,
    'primary': $primaryColor,
);

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

:root {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    --traton-blue-200: #{$blue-200};
    --traton-blue-400: #{$blue-400};
    --traton-blue-800: #{$blue-800};
    --traton-blue-1000: #{$blue-1000};
    --traton-warm-gray-100: #{$warmGray-100};
    --traton-warm-gray-200: #{$warmGray-200};
    --traton-warm-gray-300: #{$warmGray-300};
    --traton-warm-gray-400: #{$warmGray-400};
    --traton-warm-gray-500: #{$warmGray-500};
    --traton-yellow-500: #{$yellow-500};
    --traton-yellow-700: #{$yellow-700};
    --traton-warm-white: #{$warm-white};
    --traton-white: white;
    --traton-black: black;

    font-family: 'traton';
    --font-family: 'traton';
    --surface-a: #ffffff;
    --surface-b: #f8f9fa;
    --surface-c: #e9ecef;
    --surface-d: #dee2e6;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #495057;
    --text-color-secondary: #6c757d;
    --primary-color: #{$blue-400};
    --primary-color-text: white;
    --surface-0: #ffffff;
    --surface-50: #fafafa;
    --surface-100: #f5f5f5;
    --surface-200: #eeeeee;
    --surface-300: #e0e0e0;
    --surface-400: #bdbdbd;
    --surface-500: #9e9e9e;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --content-padding: #{$panelContentPadding};
    --inline-spacing: #{$inlineSpacing};
    --border-radius: #{$borderRadius};
    --surface-ground: #f8f9fa;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #dee2e6;
    --surface-hover: #e9ecef;
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    --focus-ring: #{$focusShadow};
    color-scheme: light;
}
