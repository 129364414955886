// Thin
@font-face {
    font-family: 'traton';
    font-style: normal;
    font-weight: 100;
    src: url('./fonts/display/TRATON-Type-Display-Thin.woff2') format('woff2');
}
@font-face {
    font-family: 'traton';
    font-style: italic;
    font-weight: 100;
    src: url('./fonts/display/TRATON-Type-Display-ThinItalic.woff2') format('woff2');
}

// ExtraLight
@font-face {
    font-family: 'traton';
    font-style: normal;
    font-weight: 200;
    src: url('./fonts/display/TRATON-Type-Display-ExtraLight.woff2') format('woff2');
}
@font-face {
    font-family: 'traton';
    font-style: italic;
    font-weight: 200;
    src: url('./fonts/display/TRATON-Type-Display-ExtraLightItalic.woff2') format('woff2');
}

// Light
@font-face {
    font-family: 'traton';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/display/TRATON-Type-Display-Light.woff2') format('woff2');
}
@font-face {
    font-family: 'traton';
    font-style: italic;
    font-weight: 300;
    src: url('./fonts/display/TRATON-Type-Display-LightItalic.woff2') format('woff2');
}

// Regular
@font-face {
    font-family: 'traton';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/display/TRATON-Type-Display-Regular.woff2') format('woff2');
}

// Medium
@font-face {
    font-family: 'traton';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/display/TRATON-Type-Display-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'traton';
    font-style: italic;
    font-weight: 500;
    src: url('./fonts/display/TRATON-Type-Display-MediumItalic.woff2') format('woff2');
}

// SemiBold
@font-face {
    font-family: 'traton';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/display/TRATON-Type-Display-SemiBold.woff2') format('woff2');
}
@font-face {
    font-family: 'traton';
    font-style: italic;
    font-weight: 600;
    src: url('./fonts/display/TRATON-Type-Display-SemiBoldItalic.woff2') format('woff2');
}

// Bold
@font-face {
    font-family: 'traton';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/display/TRATON-Type-Display-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'traton';
    font-style: italic;
    font-weight: 700;
    src: url('./fonts/display/TRATON-Type-Display-BoldItalic.woff2') format('woff2');
}

// ExtraBold
@font-face {
    font-family: 'traton';
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/display/TRATON-Type-Display-ExtraBold.woff2') format('woff2');
}
@font-face {
    font-family: 'traton';
    font-style: italic;
    font-weight: 800;
    src: url('./fonts/display/TRATON-Type-Display-ExtraBoldItalic.woff2') format('woff2');
}
