/* Customizations to the designer theme should be defined here */

// Active effect
.p-menuitem-content:has(> .p-menuitem-link-active) {
    background-color: rgba($primaryColor, 0.2);
}

// Header
.p-header {
    .p-menubar {
        background-color: $primaryDarkerColor;
        border: none;
        border-radius: 0;
        color: white;

        .p-menuitem.p-highlight > .p-menuitem-content,
        .p-focus > .p-menuitem-content,
        .p-menuitem-content:hover,
        .p-menubar-button:hover {
            background-color: rgba($primaryColor, 0.2);
        }

        .p-menubar-button:focus {
            box-shadow: 0 0 0 0.2rem rgba($primaryColor, 0.5);
        }

        a,
        .p-button,
        .p-menuitem-text,
        .p-menuitem-icon,
        .p-submenu-icon,
        .p-menubar-button {
            color: white;
        }

        .p-submenu-list,
        .p-menubar-root-list {
            background-color: $primaryDarkerColor;
        }
    }
}

// Spinner
.p-progress-spinner-circle {
    animation:
        p-progress-spinner-dash 1.5s ease-in-out infinite,
        traton-color 6s ease-in-out infinite;

    @keyframes traton-color {
        100%,
        0% {
            stroke: $primaryColor;
        }
    }
}
